import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// import clinca4youOdonto from "../../Assets/Projects/clinca4youOdonto.png";
import dbsp from "../../Assets/Projects/dbsp.png";
import euassisti from "../../Assets/Projects/euassisti.png";
//import imoveismaia from "../../Assets/Projects/imoveismaia.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Meus <strong className="purple">Projetos </strong>Recentes
        </h1>
        <p style={{ color: "white" }}>
          Aqui estão alguns projetos em que trabalhei recentemente.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={euassisti}
              isBlog={false}
              title="Eu Assisti"
              description="Organizar filmes/series saber onde assistir e muito mais"
              link="https://www.euassisti.com.br/"
            />
          </Col>          
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={clinca4youOdonto}
              isBlog={false}
              title="4you Odonto"
              description="Site institucional utilizando Next.js"
              link="https://www.4youodonto.com.br/"
            />
          </Col> */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dbsp}
              isBlog={false}
              title="dbSP"
              description="Site institucional utilizando Next.js"
              link="https://www.dbsp.com.br/"
            />
          </Col>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={imoveismaia}
              isBlog={false}
              title="Imóveis Maia"
              description="Site institucional utilizando Next.js"
              link="https://www.imoveismaia.com.br/"
            />
          </Col>           */}
        
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
