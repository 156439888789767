import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
// import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import Hardwarestack from "./Hardwarestack";
// import SpotifyPlayer from "../Spotify/SpotifyPlayer";
import { SiSpotify } from "react-icons/si";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Quem <strong className="purple">Sou eu</strong>
            </h1>
            <Aboutcard />
            <h1 className="project-heading">
          {/* <strong className="purple">Ouvindo</strong> Agora <SiSpotify /> */}
        </h1>
          {/* <SpotifyPlayer
          client_id="62a2f4f09ccf4e2eb36da2d6a6bd0057"
          client_secret="06742d7ee6af420c935f7fc230bb9141"
          refresh_token="AQB7YoI8pyagtTqKYeT1WSloH6nRrMlyt9I9gtrVHEpYpc7M9Zm5QNmybxfnLHgRK4rsSMcDpVCF0ru6JcxELrF1sKfZiyzbW1cLKgMW3ljxDqPGFckOF3_eSwgd8Bw5UNA"
        />             */}
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Habilidades <strong className="purple">Profissionais </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">Ferramentas</strong> Utilizadas
        </h1>
        <Toolstack />

        <h1 className="project-heading">
          <strong className="purple">Hardwares</strong> Utilizados
        </h1>
        <Hardwarestack />


        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
