import React from "react";
import Card from "react-bootstrap/Card";
import { ImMug, ImAirplane } from "react-icons/im";
import { MdLocalMovies, MdTwoWheeler } from "react-icons/md";
import { SiSpotify } from "react-icons/si";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Olá, eu sou <span className="purple">Danilo Barreto Bezerra </span>
            de <span className="purple"> São Paulo, Brasil.</span>
            <br />Eu sou desenvolvedor web Sênior.
            <br />
            <br />
            Além de programar, outras atividades que adoro fazer!
          </p>
          <ul>
          <li className="about-activity">
              <SiSpotify /> Escutar Música
            </li>                
            <li className="about-activity">
              <MdLocalMovies /> Assistir filmes e series
            </li>            
            <li className="about-activity">
              <MdTwoWheeler /> Andar de moto
            </li>
            <li className="about-activity">
              <ImMug /> Bber com amigos
            </li>
            <li className="about-activity">
              <ImAirplane /> Viajar
            </li>
          </ul>

{/*           <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
