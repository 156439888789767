import React from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import Slider from "../Slider";

function Blog() {
  return (
    <Container fluid className="project-section">
      <Particle />
        <h1 className="project-heading">
          Publicações <strong className="purple">Realizadas </strong>
        </h1>
        <p style={{ color: "white" }}>
          Algumas das publicações que realizei
        </p>
        <Container>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            <Slider />     
          </Row>
        </Container>
    </Container>
  );
}

export default Blog;
