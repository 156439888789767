import React from "react";
import { Card, Col } from "react-bootstrap";
import { BsLink } from "react-icons/bs";

export default function MediumCard(props) {

  return (
      <Col md={4}>
        <a
              className="blog-link"
              href={props.link}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "1.2em" }}
            >
              <Card className="blog-card-view">
                <Card.Img variant="top" src={props.thumbnail} fluid={true} />
                <Card.Footer>
                  <BsLink />
                  &nbsp;
                  {props.title}
                  <p style={{ marginBlockEnd: "0em" }}>{props.site}</p>
                </Card.Footer>
              </Card>
            </a>
          
    </Col>  
    );

    
      
}